import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line, PieChart, Pie, Cell, LabelList } from 'recharts';
import { Camera } from "lucide-react";

const monthNames = ['September', 'October', 'November', 'December', 'January', 'February', 'March', 'April', 'May', 'June'];

const monthlyData = [
  {
    month: 'September',
    lessonsOffered: 12,
    lessonsReceived: 11,
    unusedLessonSlots: 1,
    lessonsFilled: 91.67,
    unusedLessonFunds: 34.66,
    activePrograms: 1,
    activeStudios: 1,
    studentAttrition: 0,
    studentAdditions: 0,
    performances: 0,
    expenses: 1288.47,
  },
  {
    month: 'October',
    lessonsOffered: 653,
    lessonsReceived: 731,
    unusedLessonSlots: 21,
    lessonsFilled: 96.78,
    unusedLessonFunds: 765.70,
    activePrograms: 31,
    activeStudios: 57,
    studentAttrition: 4,
    studentAdditions: 12,
    performances: 0,
    expenses: 34841.33,
  },
  {
    month: 'November',
    lessonsOffered: 1114,
    lessonsReceived: 1247,
    unusedLessonSlots: 99,
    lessonsFilled: 91.11,
    unusedLessonFunds: 3612.74,
    activePrograms: 33,
    activeStudios: 62,
    studentAttrition: 7,
    studentAdditions: 14,
    performances: 0,
    expenses: 44104.92,
  },
  {
    month: 'December',
    lessonsOffered: 898,
    lessonsReceived: 987,
    unusedLessonSlots: 65,
    lessonsFilled: 92.76,
    unusedLessonFunds: 2447.60,
    activePrograms: 33,
    activeStudios: 64,
    studentAttrition: 10,
    studentAdditions: 6,
    performances: 0,
    expenses: 35072.50,
  },
  {
    month: 'January',
    lessonsOffered: 1249,
    lessonsReceived: 1419,
    unusedLessonSlots: 104,
    lessonsFilled: 91.67,
    unusedLessonFunds: 3875.61,
    activePrograms: 33,
    activeStudios: 63,
    studentAttrition: 11,
    studentAdditions: 9,
    performances: 7,
    expenses: 52084.36,
  },
  {
    month: 'February',
    lessonsOffered: 1137,
    lessonsReceived: 1314,
    unusedLessonSlots: 78,
    lessonsFilled: 93.14,
    unusedLessonFunds: 2961.59,
    activePrograms: 33,
    activeStudios: 63,
    studentAttrition: 10,
    studentAdditions: 17,
    performances: 33,
    expenses: 50180.00,
  },
  {
    month: 'March',
    lessonsOffered: 1205.65,
    lessonsReceived: 1336.65,
    unusedLessonSlots: 87,
    lessonsFilled: 92.78,
    unusedLessonFunds: 3233.82,
    activePrograms: 33,
    activeStudios: 65,
    studentAttrition: 5,
    studentAdditions: 9,
    performances: 7,
    expenses: 49609.00,
  },
  {
    month: 'April',
    lessonsOffered: 1149.32,
    lessonsReceived: 1262.32,
    unusedLessonSlots: 78,
    lessonsFilled: 93.21,
    unusedLessonFunds: 2810.30,
    activePrograms: 34,
    activeStudios: 67,
    studentAttrition: 2,
    studentAdditions: 5,
    performances: 3,
    expenses: 45723.87,
  },
  {
    month: 'May',
    lessonsOffered: 1330,
    lessonsReceived: 1470,
    unusedLessonSlots: 71,
    lessonsFilled: 94.66,
    unusedLessonFunds: 2450.30,
    activePrograms: 32,
    activeStudios: 63,
    studentAttrition: 9,
    studentAdditions: 1,
    performances: 25,
    expenses: 57857.12,
  },
  {
    month: 'June',
    lessonsOffered: 275,
    lessonsReceived: 339,
    unusedLessonSlots: 22,
    lessonsFilled: 92.00,
    unusedLessonFunds: 743.94,
    activePrograms: 18,
    activeStudios: 35,
    studentAttrition: 0,
    studentAdditions: 0,
    performances: 19,
    expenses: 17372.04,
  },
];

const COLORS = [
  'rgb(var(--color-blue))', 
  'rgb(var(--color-light-blue))', 
  'rgb(var(--color-orange))', 
  'rgb(var(--color-light-grey))'
];

const sortByValue = (data) => [...data].sort((a, b) => b.value - a.value);


const studentsByRegion = [
  { name: 'Adirondacks', value: 31 },
  { name: 'Chicago', value: 58 },
  { name: 'Jersey City', value: 36 },
  { name: 'Newark', value: 115 },
  { name: 'NYC', value: 122 },
  { name: 'Philadelphia', value: 120 },
  { name: 'Seattle', value: 17 },
  { name: 'SF', value: 94 },
  { name: 'Alumni Program', value: 7 },
];

const mentorsByRegion = [
  { name: 'Adirondacks', value: 8 },
  { name: 'Chicago', value: 5 },
  { name: 'Jersey City', value: 2 },
  { name: 'Newark', value: 7 },
  { name: 'NYC', value: 6 },
  { name: 'Philadelphia', value: 8 },
  { name: 'Seattle', value: 2 },
  { name: 'SF', value: 7 },
  { name: 'Alumni Program', value: 4 },
];

const regionPerformance = [
  { name: 'Alumni Program', Utilization: 100 },
  { name: 'NYC', Utilization: 97 },
  { name: 'Philadelphia', Utilization: 95 },
  { name: 'Adirondacks', Utilization: 95 },
  { name: 'Jersey City', Utilization: 93 },
  { name: 'Seattle', Utilization: 92 },
  { name: 'SF', Utilization: 92 },
  { name: 'Chicago', Utilization: 89 },
  { name: 'Newark', Utilization: 87 },
];

const monthlyUtilization = [
  { month: 'September', Utilization: 91.67 },
  { month: 'October', Utilization: 96.78 },
  { month: 'November', Utilization: 91.11 },
  { month: 'December', Utilization: 92.76 },
  { month: 'January', Utilization: 91.67 },
  { month: 'February', Utilization: 93.14 },
  { month: 'March', Utilization: 92.78 },
  { month: 'April', Utilization: 93.21 },
  { month: 'May', Utilization: 94.66 },
  { month: 'June', Utilization: 92.00 },
];

const schoolsByRegion = [
  { name: 'Adirondacks', value: 12 },
  { name: 'Chicago', value: 4 },
  { name: 'Jersey City', value: 2 },
  { name: 'Newark', value: 6 },
  { name: 'NYC', value: 6 },
  { name: 'Philadelphia', value: 5 },
  { name: 'Seattle', value: 2 },
  { name: 'SF', value: 7 },
  { name: 'Alumni Program', value: 1 },
];

const studiosByRegion = [
  { name: 'Adirondacks', value: 4 },
  { name: 'Chicago', value: 6 },
  { name: 'Jersey City', value: 3 },
  { name: 'Newark', value: 10 },
  { name: 'NYC', value: 11 },
  { name: 'Philadelphia', value: 12 },
  { name: 'Seattle', value: 2 },
  { name: 'SF', value: 11 },
  { name: 'Alumni Program', value: 1 },
];

const studioFormats = [
  { name: 'Private Voice', value: 41.7 },
  { name: 'Vocal Theater/Hybrid', value: 12 },
  { name: 'Private Piano', value: 3.3 },
  { name: 'Private Instrumental', value: 3 },
];

const monthlyExpenditures = [
  { month: 'September', value: 1288 },
  { month: 'October', value: 34841 },
  { month: 'November', value: 44105 },
  { month: 'December', value: 35073 },
  { month: 'January', value: 52084 },
  { month: 'February', value: 50180 },
  { month: 'March', value: 49609 },
  { month: 'April', value: 45724 },
  { month: 'May', value: 57857 },
  { month: 'June', value: 17372 },
];

const schoolsGrowth = [
  { year: '2022-23', value: 28, percentChange: null },
  { year: '2023-24', value: 34, percentChange: 21 },
  { year: '2024-25', value: 41, percentChange: 21 },
];

const studiosGrowth = [
  { year: '2022-23', value: 48, percentChange: null },
  { year: '2023-24', value: 60, percentChange: 25 },
  { year: '2024-25', value: 74.5, percentChange: 24 },
];

const studentsGrowth = [
  { year: '2022-23', value: 449, percentChange: null },
  { year: '2023-24', value: 600, percentChange: 34 },
  { year: '2024-25', value: 716, percentChange: 18 },
];




const ArtSmartDashboard = () => {
  const [activeTab, setActiveTab] = useState('Overview');
  const [activeRegion, setActiveRegion] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  const exportDashboard = () => {
    // Helper function to convert array of objects to CSV rows
    const arrayToCSV = (arr, headers) => {
      const headerRow = headers.join(',');
      const rows = arr.map(obj => headers.map(header => JSON.stringify(obj[header] ?? '')).join(','));
      return [headerRow, ...rows].join('\n');
    };

    let csvContent = "data:text/csv;charset=utf-8,";

    // Students section
    csvContent += "Students\n";
    csvContent += `Total Students,${600}\n`;
    csvContent += `Student Retention,${90.33}%\n`;
    csvContent += `All Time Students Served,${1732}\n\n`;
    csvContent += arrayToCSV(studentsByRegion, ['name', 'value']) + "\n\n";

    // Mentors section
    csvContent += "Mentors\n";
    csvContent += `Total Mentors,${43}\n`;
    csvContent += arrayToCSV(mentorsByRegion, ['name', 'value']) + "\n\n";

    // Lessons and Performances section
    csvContent += "Lessons and Performances\n";
    csvContent += `Lessons Offered,${9023}\n`;
    csvContent += `Lessons Received,${10117}\n`;
    csvContent += `All Time Lessons Received,${45212}\n`;
    csvContent += `Unused Lesson Slots,${626}\n`;
    csvContent += `Year Total Lesson Slot Utilization,${93}%\n`;
    csvContent += `Unused Lesson Funds,${22936.26}\n`;
    csvContent += `Performances,${94}\n\n`;
    csvContent += "Region Performance\n";
    csvContent += arrayToCSV(regionPerformance, ['name', 'Utilization']) + "\n\n";
    csvContent += "Monthly Utilization\n";
    csvContent += arrayToCSV(monthlyUtilization, ['month', 'Utilization']) + "\n\n";

    // Schools section
    csvContent += "Schools/Programs\n";
    csvContent += `Total Schools/Programs,${45}\n`;
    csvContent += arrayToCSV(schoolsByRegion, ['name', 'value']) + "\n\n";

    // Studios section
    csvContent += "Studios\n";
    csvContent += `Total Studios,${60}\n`;
    csvContent += "Studios by Region\n";
    csvContent += arrayToCSV(studiosByRegion, ['name', 'value']) + "\n\n";
    csvContent += "Studio Formats\n";
    csvContent += arrayToCSV(studioFormats, ['name', 'value']) + "\n\n";

    // Expenditures section
    csvContent += "Expenditures\n";
    csvContent += `Total Expenditures,${372192}\n`;
    csvContent += arrayToCSV(monthlyExpenditures, ['month', 'value']) + "\n\n";

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "artsmart_dashboard_data.csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
    document.body.removeChild(link);
  };

  const renderStudentsSection = () => (
    <div className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300">
      <h2 className="text-2xl font-headline text-blue mb-4">Students</h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div>
          <p className="text-sm sm:text-base md:text-lg font-subhead text-blue mb-2">Total Students: 600</p>
          <p className="text-sm sm:text-base md:text-lg font-subhead text-blue mb-2">Student Retention: 90.33%</p>
          <p className="text-sm sm:text-base md:text-lg font-subhead text-blue mb-4">All Time Students Served: 1,732</p>
          <div className="h-[400px] w-full">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={sortByValue(studentsByRegion)} layout="vertical" margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" width={120} tick={{ fill: 'rgb(var(--color-blue))', fontSize: 12 }} interval={0} />
                <Tooltip contentStyle={{backgroundColor: 'rgb(var(--color-light-grey))', borderColor: 'rgb(var(--color-blue))'}} />
                <Bar dataKey="value" fill="rgb(var(--color-blue))" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="h-[400px] w-full">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={[{ name: 'Retained', value: 542 }, { name: 'Not Retained', value: 58 }]}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
              >
                {[0, 1].map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip contentStyle={{backgroundColor: 'rgb(var(--color-light-grey))', borderColor: 'rgb(var(--color-blue))'}} />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );

  const renderMentorsSection = () => (
    <div className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300">
      <h2 className="text-2xl font-headline text-blue mb-4">Mentors</h2>
      <p className="text-sm sm:text-base md:text-lg font-subhead text-blue mb-4">Total Mentors: 43</p>
      <div className="h-[400px] w-full">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={sortByValue(mentorsByRegion)} layout="vertical" margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
            <XAxis type="number" />
            <YAxis dataKey="name" type="category" width={120} tick={{ fill: 'rgb(var(--color-blue))', fontSize: 12 }} interval={0} />
            <Tooltip contentStyle={{backgroundColor: 'rgb(var(--color-light-grey))', borderColor: 'rgb(var(--color-blue))'}} />
            <Bar dataKey="value" fill="rgb(var(--color-light-blue))" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );

  const renderLessonsAndPerformancesSection = () => (
    <div className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300">
      <h2 className="text-2xl font-headline text-blue mb-6">Lessons and Performances</h2>
      <div className="flex flex-col space-y-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div>
            <p className="text-sm sm:text-base md:text-lg font-subhead text-blue mb-2">Lessons Offered: 9,023</p>
            <p className="text-sm sm:text-base md:text-lg font-subhead text-blue mb-2">Lessons Received: 10,117</p>
            <p className="text-sm sm:text-base md:text-lg font-subhead text-blue mb-2">All Time Lessons Received: 45,212</p>
            <p className="text-sm sm:text-base md:text-lg font-subhead text-blue mb-2">Performances: 94 Mid and End-of-Year Performances</p>
            <br />
            <p className="text-sm sm:text-base md:text-lg font-subhead text-blue mb-2">Unused Lesson Slots: 626</p>
            <p className="text-sm sm:text-base md:text-lg font-subhead text-blue mb-2">Year Total Lesson Slot Utilization: 93%</p>
            <p className="text-sm sm:text-base md:text-lg font-subhead text-blue mb-2">Unused Lesson Funds: $22,936.26</p>
          </div>
          <div className="flex flex-col h-[400px]">
            <h3 className="text-lg font-subhead text-blue mb-4">Lesson Utilization % by Region</h3>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={sortByValue(regionPerformance)} layout="vertical" margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
                <XAxis type="number" domain={[80, 100]} tickFormatter={(value) => `${value}%`} />
                <YAxis dataKey="name" type="category" width={120} tick={{ fill: 'rgb(var(--color-blue))', fontSize: 12 }} interval={0} />
                <Tooltip formatter={(value) => `${value}%`} contentStyle={{backgroundColor: 'rgb(var(--color-light-grey))', borderColor: 'rgb(var(--color-blue))'}} />
                <Bar dataKey="Utilization" fill="rgb(var(--color-orange))" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="flex flex-col h-[400px]">
          <h3 className="text-lg font-subhead text-blue mb-4">Monthly Lesson Slot Utilization</h3>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={monthlyUtilization} margin={{ top: 5, right: 30, left: 20, bottom: 60 }}>
              <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
              <XAxis 
                dataKey="month" 
                tick={{ fill: 'rgb(var(--color-blue))', fontSize: 12 }}
                height={60}
                angle={-45}
                textAnchor="end"
                interval={0}
              />
              <YAxis domain={[85, 100]} tickFormatter={(value) => `${value}%`} />
              <Tooltip formatter={(value) => `${value}%`} contentStyle={{backgroundColor: 'rgb(var(--color-light-grey))', borderColor: 'rgb(var(--color-blue))'}} />
              <Legend />
              <Line type="monotone" dataKey="Utilization" stroke="rgb(var(--color-blue))" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );

  const renderSchoolsSection = () => (
    <div className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300">
      <h2 className="text-2xl font-headline text-blue mb-4">Schools/Programs</h2>
      <p className="text-sm sm:text-base md:text-lg font-subhead text-blue mb-4">Total Schools/Programs: 45</p>
      <div className="h-[400px] w-full">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={sortByValue(schoolsByRegion)} margin={{ top: 5, right: 30, left: 20, bottom: 60 }}>
            <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
            <XAxis 
              dataKey="name" 
              tick={{ fill: 'rgb(var(--color-blue))', fontSize: 12 }}
              height={60}
              angle={-45}
              textAnchor="end"
              interval={0}
            />
            <YAxis />
            <Tooltip contentStyle={{backgroundColor: 'rgb(var(--color-light-grey))', borderColor: 'rgb(var(--color-blue))'}} />
            <Bar dataKey="value" fill="rgb(var(--color-light-blue))" />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <p className="text-sm sm:text-base md:text-lg font-body text-blue">
        *Includes all Adirondack schools served (12) and Alumni Program (1).
      </p>
    </div>
  );

  const renderStudiosSection = () => (
    <div className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300">
      <h2 className="text-2xl font-headline text-blue mb-4">Studios</h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div>
          <h3 className="text-xl font-subhead text-blue mb-2">Studios by Region</h3>
          <p className="text-sm sm:text-base md:text-lg font-subhead text-blue mb-4">Total Studios: 60</p>
          <div className="h-[400px] w-full">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={sortByValue(studiosByRegion)} margin={{ top: 5, right: 30, left: 20, bottom: 60 }}>
                <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
                <XAxis 
                  dataKey="name" 
                  tick={{ fill: 'rgb(var(--color-blue))', fontSize: 12 }}
                  height={60}
                  angle={-45}
                  textAnchor="end"
                  interval={0}
                />
                <YAxis />
                <Tooltip contentStyle={{backgroundColor: 'rgb(var(--color-light-grey))', borderColor: 'rgb(var(--color-blue))'}} />
                <Bar dataKey="value" fill="rgb(var(--color-orange))" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div>
          <h3 className="text-xl font-subhead text-blue mb-2">Studio Formats</h3>
          <p className="text-sm sm:text-base md:text-lg font-subhead text-blue mb-4">Total Studios: 60</p>
          <div className="h-[400px] w-full">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={sortByValue(studioFormats)} margin={{ top: 5, right: 30, left: 20, bottom: 60 }}>
                <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
                <XAxis 
                  dataKey="name" 
                  tick={{ fill: 'rgb(var(--color-blue))', fontSize: 12 }}
                  height={60}
                  angle={-45}
                  textAnchor="end"
                  interval={0}
                />
                <YAxis />
                <Tooltip contentStyle={{backgroundColor: 'rgb(var(--color-light-grey))', borderColor: 'rgb(var(--color-blue))'}} />
                <Bar dataKey="value" fill="rgb(var(--color-light-blue))" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );

  const renderExpendituresSection = () => (
    <div className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300">
      <h2 className="text-2xl font-headline text-blue mb-4">Expenditures</h2>
      <p className="text-sm sm:text-base md:text-lg font-subhead text-blue mb-4">Total Expenditures: $372,192</p>
      <div className="h-[400px] w-full">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={monthlyExpenditures}
            margin={{ top: 5, right: 30, left: 20, bottom: 60 }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
            <XAxis
              dataKey="month"
              tick={{ fill: 'rgb(var(--color-blue))', fontSize: 12 }}
              height={60}
              tickFormatter={(value) => value.substring(0, 3)}
              interval={0}
              angle={-45}
              textAnchor="end"
            />
            <YAxis
              tick={{ fill: 'rgb(var(--color-blue))', fontSize: 12 }}
              tickFormatter={(value) => `$${value.toLocaleString()}`}
            />
            <Tooltip
formatter={(value) => `$${value.toLocaleString()}`}
contentStyle={{backgroundColor: 'rgb(var(--color-light-grey))', borderColor: 'rgb(var(--color-blue))'}}
/>
<Legend wrapperStyle={{ paddingTop: '20px' }} />
<Line type="monotone" dataKey="value" name="Expenditure" stroke="rgb(var(--color-orange))" activeDot={{ r: 8 }} />
</LineChart>
</ResponsiveContainer>
</div>
<p className="text-sm sm:text-base md:text-lg font-body text-blue">
*Includes direct program expenses associated with Mentor and Accompanist payments, excludes general Program Operations Expenses.
</p>
</div>
);

const renderGrowthSection = () => {
  const renderGrowthChart = (data, title, yAxisLabel, caption) => {
    const renderCustomLabel = (props) => {
      const { x, y, width, index } = props;
      if (index === 0) return null; // Don't show label for the first bar

      const percentChange = data[index].percentChange;
      
      return (
        <text 
          x={x + width / 2} 
          y={y - 10} 
          fill="rgb(var(--color-blue))" 
          textAnchor="middle" 
          dominantBaseline="middle" 
          fontSize="12"
        >
          +{percentChange}%
        </text>
      );
    };

    return (
      <div className="mb-12"> {/* Increased bottom margin for more space between charts */}
        <p className="text-sm sm:text-base md:text-lg font-subhead text-blue mb-4">{title}</p>
        <div className="h-[300px] w-full">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart 
              data={data} 
              margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
              <XAxis 
                dataKey="year" 
                tick={{ fill: 'rgb(var(--color-blue))', fontSize: 12 }}
                height={60}
                angle={-45}
                textAnchor="end"
                interval={0}
              />
              <YAxis />
              <Tooltip 
                contentStyle={{backgroundColor: 'rgb(var(--color-light-grey))', borderColor: 'rgb(var(--color-blue))'}}
                formatter={(value, name, props) => {
                  const { value: count, percentChange } = props.payload;
                  return [
                    `${count.toFixed(0)} ${percentChange !== null ? `(+${percentChange}%)` : ''}`,
                    yAxisLabel
                  ];
                }}
                labelFormatter={(value) => `Year: ${value}`}
              />
              <Bar dataKey="value" fill="rgb(var(--color-light-blue))">
                <LabelList content={renderCustomLabel} position="top" />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
        {caption && (
          <p className="text-sm sm:text-base md:text-lg font-body text-blue">
            {caption}
          </p>
        )}
      </div>
    );
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300">
      <h2 className="text-2xl font-headline text-blue mb-6">Growth</h2>
      
      {renderGrowthChart(
        schoolsGrowth, 
        "School/Program Growth*", 
        "Schools",
        "*Considers Adirondack Program and Alumni Program each as 1 School/Program"
      )}
      {renderGrowthChart(
        studiosGrowth, 
        "Studio Growth", 
        "Studios",
        ""
      )}
      {renderGrowthChart(
        studentsGrowth, 
        "Student Growth*", 
        "Students",
        "*Includes Actual figures for 22-23 and 23-24 and \"at minimum\" projections for 24-25."
      )}
    </div>
  );
};

  // const renderGrowthSection = () => {
  //   const CustomBarLabel = (props) => {
  //     const { x, y, width, value, index } = props;
  //     if (index === 0) return null; // Don't show label for the first bar

  //     return (
  //       <text x={x + width / 2} y={y - 10} fill="rgb(var(--color-blue))" textAnchor="middle" dominantBaseline="middle">
  //         +21%
  //       </text>
  //     );
  //   };

  //   return (
  //     <div className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300">
  //       <h2 className="text-2xl font-headline text-blue mb-4">Growth</h2>
  //       <p className="text-sm sm:text-base md:text-lg font-subhead text-blue mb-4">School/Program Growth</p>
  //       <div className="h-[400px] w-full">
  //         <ResponsiveContainer width="100%" height="100%">
  //           <BarChart data={schoolsGrowth} margin={{ top: 20, right: 30, left: 20, bottom: 60 }}>
  //             <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
  //             <XAxis 
  //               dataKey="name" 
  //               tick={{ fill: 'rgb(var(--color-blue))', fontSize: 12 }}
  //               height={60}
  //               angle={-45}
  //               textAnchor="end"
  //               interval={0}
  //             />
  //             <YAxis />
  //             <Tooltip 
  //               contentStyle={{backgroundColor: 'rgb(var(--color-light-grey))', borderColor: 'rgb(var(--color-blue))'}}
  //               formatter={(value, name, props) => [props.payload.value_percentage, 'Schools']}
  //               labelFormatter={(value) => `Year: ${value}`}
  //             />
  //             <Bar dataKey="value" fill="rgb(var(--color-light-blue))">
  //               <Label content={CustomBarLabel} position="top" />
  //             </Bar>
  //           </BarChart>
  //         </ResponsiveContainer>
  //       </div>
  //       <div className="h-[50px] w-full">
  //       <p className="text-sm sm:text-base md:text-lg font-body text-blue">*Considers Adirondack Program and Alumni Program each as 1 School/Program.</p>
  //       </div>
  //     <p className="text-sm sm:text-base md:text-lg font-subhead text-blue mb-4">Studio Growth</p>
  //     <div className="h-[400px] w-full">
  //       <ResponsiveContainer width="100%" height="100%">
  //         <BarChart data={studiosGrowth} margin={{ top: 5, right: 30, left: 20, bottom: 60 }}>
  //           <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
  //           <XAxis 
  //             dataKey="name" 
  //             tick={{ fill: 'rgb(var(--color-blue))', fontSize: 12 }}
  //             height={60}
  //             angle={-45}
  //             textAnchor="end"
  //             interval={0}
  //           />
  //           <YAxis />
  //           <Tooltip contentStyle={{backgroundColor: 'rgb(var(--color-light-grey))', borderColor: 'rgb(var(--color-blue))'}} />
  //           <Bar dataKey="value" fill="rgb(var(--color-light-blue))" />
  //         </BarChart>
  //       </ResponsiveContainer>
  //     </div>
  //     <p className="text-sm sm:text-base md:text-lg font-subhead text-blue mb-4">Student Growth</p>
  //     <div className="h-[400px] w-full">
  //       <ResponsiveContainer width="100%" height="100%">
  //         <BarChart data={studentsGrowth} margin={{ top: 5, right: 30, left: 20, bottom: 60 }}>
  //           <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
  //           <XAxis 
  //             dataKey="name" 
  //             tick={{ fill: 'rgb(var(--color-blue))', fontSize: 12 }}
  //             height={60}
  //             angle={-45}
  //             textAnchor="end"
  //             interval={0}
  //           />
  //           <YAxis />
  //           <Tooltip contentStyle={{backgroundColor: 'rgb(var(--color-light-grey))', borderColor: 'rgb(var(--color-blue))'}} />
  //           <Bar dataKey="value" fill="rgb(var(--color-light-blue))" />
  //         </BarChart>
  //       </ResponsiveContainer>
  //     </div>
  //     <div className="h-[50px] w-full">
  //     <p className="text-sm sm:text-base md:text-lg font-body text-blue">*Includes Actual figures for 22-23 and 23-24 and "at minimum" projections for 24-25.</p>
  //     </div>

  //   </div>
  // );

const renderOverviewTab = () => (
<div className="grid grid-cols-1 gap-6">
{renderStudentsSection()}
{renderMentorsSection()}
{renderLessonsAndPerformancesSection()}
{renderSchoolsSection()}
{renderStudiosSection()}
{renderExpendituresSection()}
{renderGrowthSection()}
</div>
);

const renderMonthTab = (monthName) => {
  const monthData = monthlyData.find(data => data.month === monthName);
  
  if (!monthData) {
    return <p className="text-lg font-subhead text-blue">No data available for {monthName}.</p>;
  }

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      {/* Lessons Information */}
      <div className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300">
        <h2 className="text-2xl font-headline text-blue mb-4">Lessons Information</h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-sm sm:text-base md:text-lg font-subhead text-blue mb-2">Lessons Offered: {monthData.lessonsOffered.toLocaleString()}</p>
            <p className="text-sm sm:text-base md:text-lg font-subhead text-blue mb-2">Lessons Received: {monthData.lessonsReceived.toLocaleString()}</p>
            <p className="text-sm sm:text-base md:text-lg font-subhead text-blue mb-2">Unused Lesson Slots: {monthData.unusedLessonSlots}</p>
          </div>
          <div>
            <p className="text-sm sm:text-base md:text-lg font-subhead text-blue mb-2">Lessons Filled: {monthData.lessonsFilled.toFixed(2)}%</p>
            <p className="text-sm sm:text-base md:text-lg font-subhead text-blue mb-2">Unused Lesson Funds: ${monthData.unusedLessonFunds.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
          </div>
        </div>
        <div className="h-[300px] w-full mt-4">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={[
              {name: 'Offered', value: monthData.lessonsOffered},
              {name: 'Received', value: monthData.lessonsReceived},
              {name: 'Unused', value: monthData.unusedLessonSlots}
            ]}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="rgb(var(--color-blue))" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Program Info */}
      <div className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300">
        <h2 className="text-2xl font-headline text-blue mb-4">Program Information</h2>
        <p className="text-sm sm:text-base md:text-lg font-subhead text-blue mb-2">Active Programs: {monthData.activePrograms}</p>
        <p className="text-sm sm:text-base md:text-lg font-subhead text-blue mb-2">Active Studios: {monthData.activeStudios}</p>
        <div className="h-[300px] w-full mt-4">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={[
              {name: 'Active Programs', value: monthData.activePrograms},
              {name: 'Active Studios', value: monthData.activeStudios}
            ]}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="rgb(var(--color-light-blue))" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Student Changes */}
      <div className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300">
        <h2 className="text-2xl font-headline text-blue mb-4">Student Changes</h2>
        <p className="text-sm sm:text-base md:text-lg font-subhead text-blue mb-2">Student Attrition: {monthData.studentAttrition}</p>
        <p className="text-sm sm:text-base md:text-lg font-subhead text-blue mb-2">Student Additions: {monthData.studentAdditions}</p>
        <div className="h-[300px] w-full mt-4">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={[
              {name: 'Attrition', value: monthData.studentAttrition},
              {name: 'Additions', value: monthData.studentAdditions}
            ]}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="rgb(var(--color-orange))" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Performances */}
      <div className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300">
        <h2 className="text-2xl font-headline text-blue mb-4">Performances</h2>
        <p className="text-sm sm:text-base md:text-lg font-subhead text-blue mb-2">Number of Performances: {monthData.performances}</p>
        <div className="h-[300px] w-full mt-4">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={[{name: 'Performances', value: monthData.performances}]}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="rgb(var(--color-light-blue))" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Financial Information */}
      <div className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300 col-span-1 lg:col-span-2">
        <h2 className="text-2xl font-headline text-blue mb-4">Financial Information</h2>
        <p className="text-3xl sm:text-4xl md:text-5xl font-subhead text-blue mb-2">
          ${monthData.expenses.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </p>
        <p className="text-sm sm:text-base md:text-lg font-body text-blue">
          Total Expenses for {monthName}
        </p>
        <div className="mt-4">
          <p className="text-sm sm:text-base md:text-lg font-body text-blue">
          Includes direct program expenses associated with Mentor and Accompanist payments, excludes general Program Operations Expenses.
          </p>
        </div>
      </div>
    </div>
  );
};

return (
<div className="min-h-screen bg-light-grey bg-opacity-50" style={{backgroundImage: "url('data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%2307569e' fill-opacity='0.05'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E')"}}>
<header className="sticky top-0 bg-white shadow-md z-10 p-4">
<h1 className="text-2xl sm:text-3xl font-headline text-blue text-center">2023-2024 ArtSmart Program Operations</h1>
<div className="mt-4">
<select 
className="block w-full bg-white border border-blue text-blue rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-light-blue focus:border-light-blue"
value={activeTab}
onChange={(e) => setActiveTab(e.target.value)}
>
<option value="Overview">Overview</option>
{monthNames.map(month => (
<option key={month} value={month}>{month}</option>
))}
</select>
</div>
</header>

<main className="container mx-auto px-4 py-8 max-w-5xl">
  {isLoading ? (
    <div className="flex justify-center items-center h-64">
      <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-blue"></div>
    </div>
  ) : (
    activeTab === 'Overview' ? renderOverviewTab() : renderMonthTab(activeTab)
  )}

{activeRegion && (
<div className="mt-8 bg-white rounded-lg shadow-lg p-6">
<h2 className="text-xl font-subhead text-blue mb-4">Details for {activeRegion}</h2>
<p className="text-lg font-body text-blue">Utilization: {regionPerformance.find(r => r.name === activeRegion)?.Utilization || 'N/A'}%</p>
</div>
)}
</main>

<footer className="bg-white shadow-md mt-8 py-4">
  <div className="container mx-auto px-4 text-center">
    <button 
      className="bg-orange hover:bg-blue text-white font-bold py-2 px-4 rounded inline-flex items-center transition duration-300 focus:outline-none focus:ring-2 focus:ring-blue focus:ring-opacity-50"
      onClick={exportDashboard}
    >
      <Camera className="mr-2" />
      <span>Export Data</span>
    </button>
  </div>
</footer>

<button 
onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
className="fixed bottom-4 right-4 bg-blue text-white p-2 rounded-full shadow-lg hover:bg-light-blue transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-blue focus:ring-opacity-50"
aria-label="Back to top"
>
↑
</button>
</div>
);
};

export default ArtSmartDashboard;